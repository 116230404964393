import React from 'react';
import ReactDOM from 'react-dom';
import * as rdd from 'react-device-detect';

import {
//  Invitation,
//  Inviter,
//  InviterOptions,
//  Referral,
//  Registerer,
//  RegistererOptions,
//  Session,
//  SessionState,
    UserAgent,
    UserAgentOptions,
//  InvitationAcceptOptions
} from "sip.js";

import * as SIP from 'sip.js'

window.rdd = rdd;

var dev = {wrtc:false, cam:false, mic:false, spk:false, notifs:false, err:null, 'loaded':0, notifs_ext:false, wrtc_registred:null, wrtc_unregistred:null, cnx:{'crt':'none', 'active':false}, cpt:0, cams:[], cur_cam:0, localStream:null, debug:false, start:false, close:false};
//var webrtc_contraints = { mandatory: { echoCancellation: false, googEchoCancellation: false, googAutoGainControl: false, googAutoGainControl2: false, googNoiseSuppression: false, googHighpassFilter: false }, optional:[]} ;


const toHHMMSS = (num) => {
    var sec_num = Math.max(0, parseInt(num, 10));
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
};

//import React from 'react';


// Gestion Traductions

var trads = {
    "input_num": "Indiquez votre numéro",
    "stat_comm": "En communication",
    "stat_current": "Appel en cours",
    "stat_end": "Merci de votre appel !",
    "window_open": "Ouvrir plein écran",
    "window_close": "Fermer le plein écran",
    "cam_open": "Activer ma camera",
    "cam_close": "Couper ma camera",
    "hangup": "Raccrocher",
    "hook_audio": "Appel audio",
    "hook_video": "Appel vidéo",
    "pbl_inconnu": "Le WebRTC n est pas possible sur votre poste suite à un problème inconnu !",
    "pbl_connu": "Le WebRTC n est pas possible sur votre poste à cause des raisons suivantes:",
    "try_website":  "Merci d essayer votre navigateur via:",
    "try": "tester",
    "support_wrtc": "support WebRTC par le navigateur",
    "pbl_speaker": "haut-paleur indisponible",
    "pbl_mic": "micro indisponible",
    "pbl_rtcp": "attention vérifier votre config RTCPeerConnection indisponible",
    "pbl_mobile": "Le WebRTC n est pas possible sur votre poste, nous ne traitons pas les mobiles pour l instant",
    "current_ring": "Appel effectué vers le {e164} sonnerie",
    "duration": "Durée",
    "pbl_device": "Impossible de récupérer le device",
    "pbl_mic_busy": "Micro peut être déjà utilisé",
    "pbl_constraintes": "Contraintes non satisfaites",
    "pbl_nav": "Le navigateur interdit l utilisation du micro",
    "pbl_object": "Objet contraintes vide",
    "pbl_unknown": "Erreur inconnue",
    "pbl_forbid": "Le navigateur interdit l utilisation du micro",
    "cam_switch": "Changer caméra",
    "temps_comm": "Temps de comm.:",
    "echec_comm": "L'appel vers le {e164} échoué",
    "micro":"Microphone"
} ;

function _(text)
{
    try {
        if(trads && trads.hasOwnProperty(text))
            return trads[text] ;
        else
            return "["+text+"]" ;
    } catch(error) {
        return text ;
    }
}

const pre_wrtc = async (auth) => {
    var dev = {wrtc:false, cams:[], cam:false, mic:false, spk:false, err:_('pbl_inconnu')};
    try {
        dev = await checkAvailableDevices(dev) ;
        let err =  '' ;
        //let code_err = '';

        if(dev.wrtc) {
            if(window.RTCPeerConnection === undefined) {
                err += ' - '+_('pbl_rtcp')+'<br/>' ;
                //code_err = 'RTCP_config';
            }
            else {
                if(!dev.mic) {
                    err += ' - '+_('pbl_mic')+'<br/>' ;
                    //code_err = 'MIC_unavailable';
                }
                if(!dev.spk) {
                    err += ' - '+_('pbl_speaker')+'<br/>' ;
                    //code_err = 'SPK_unavailable';
                }
            }
        }
        else {
            err += ' - '+_('support_wrtc')+'<br/>' ;
            //code_err = 'WebRTC_support' ;
        }

        if(err) {
            err += _('try_website')+' <a href="https://test.webrtc.org" target="_blank">'+_('test')+'</a>' ;
            dev.err = _("pbl_connu") + err ;
        }
        else {
            var stream ;
            try {
                if(dev.cams.length>0) {
                    try {
                        stream = await navigator.mediaDevices.getUserMedia({audio: true , video: {deviceId: undefined}});
                        dev.cam = true;
                    } catch(e) {
                        stream = await navigator.mediaDevices.getUserMedia({audio: true , video: false});
                    }
                } else {
                    stream = await navigator.mediaDevices.getUserMedia({audio: true , video: false});
                }
                var devices = await gotStream(stream, 'videoRemote');
                dev = gotDevices(dev, devices);
                dev.err = null;
            } catch(err) {
                if (err.name === "NotFoundError" || err.name === "DevicesNotFoundError" ){
                    dev.err = _("pbl_device") ;
                } else if (err.name === "NotReadableError" || err.name === "TrackStartError" ){
                    dev.err = _("pbl_mic_busy") ;
                } else if (err.name === "OverconstrainedError" || err.name === "ConstraintNotSatisfiedError" ){
                    dev.err = _("pbl_constraintes") ;
                } else if (err.name === "NotAllowedError" || err.name === "PermissionDeniedError" ){
                    dev.err = _("pbl_forbid") ;
                } else if (err.name === "TypeError" || err.name === "TypeError" ){
                    dev.err = _("pbl_object") ;
                } else {
                    dev.err = _("pbl_unknown") ;
                }
            }
        }
    } catch(e) {
        dev.err = _('pbl_inconnu');
    } ;
    return dev;
}



// Utile

/*urlParam = function(name){
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    return results === null?null:results[1] ;
}*/





// Gestion Periphériques

const gotDevices = (dev, devices) => {
    dev.cams = [] ;

    for (var i = 0; i < devices.length; ++i) {
        const device = devices[i] ;
        switch (device.kind) {
            case 'videoinput':
                dev.cams.push([device.deviceId, device.label || `camera ${dev.cams.length+1}`]) ;
                break;
            case 'audioinput':
                dev.mic = true;
                break;
            case 'audiooutput':
                dev.spk = true;
                break;
            default:
        }
    }
    if (navigator.userAgent.indexOf('Firefox') !== -1  || navigator.userAgent.indexOf('Safari') !== -1)  {
        dev.spk = true;
    }
    return dev ;
}




const gotStream = async (stream, onID='videoLocal') => {
    window.stream = stream;
    document.getElementById(onID).srcObject = stream;
    return navigator.mediaDevices.enumerateDevices();
}

/*const readySwitch = async (ref) => {
    if(window.stream) {
        document.getElementById('videoRemote').srcObject = null;
        document.getElementById('videoLocal').srcObject = window.stream;
    }
}*/



const preSwitch = async (ref) => {
    var dev = {cams:[], mic:false, speak:false};
    let stream ;

    if(window.stream) {
        window.stream.getTracks().forEach(track => {
            track.stop();
        });
    }
    /*const constraints = {
        audio: false,
        video: {deviceId: ref ? {exact: ref} : undefined}
    };*/

    try {
        stream = await navigator.mediaDevices.getUserMedia({audio: true , video: {deviceId: ref}});
        dev.cam = true;
    } catch(e) {
        stream = await navigator.mediaDevices.getUserMedia({audio: true , video: true});
    }

    var devices = await gotStream(stream, 'videoLocal');
    dev = gotDevices(dev, devices);
    return dev ;
}


const noVideo = async() => {
    if (window.stream) {
        window.stream.getTracks().forEach(track => {
            track.stop();
        });
    }
}

/*const handleError = (error) => {
    console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
}*/


const checkAvailableDevices = async (dev) => {

    dev.wrtc = navigator.mediaDevices && navigator.mediaDevices.getUserMedia ? true : false ;

    if(dev.wrtc) {
        var devices = await navigator.mediaDevices.enumerateDevices();
        dev = gotDevices(dev, devices) ;
    }
    return dev;
}


/*function get_constraints(video=true, device_video=false, audio=false) {
    //var constraints = {'audio': true, 'video':false};
    var constraints = {'audio':audio?true:false, 'video':video?true:false};

    if(video&&device_video) {
        constraints.video =  {
            width: {ideal: 640},
            height: {ideal: 480}
        };
        if(device_video) {
            constraints.video.deviceId = device_video ;
        }
    }
    console.log(constraints) ;
    return constraints ;
}*/




// ReactJS





//const e = React.createElement;

function Banner(props) {
    let axiaStyle = props.url_logo ? { 'backgroundImage': 'url("'+props.url_logo+'")' } : {};
    let banner = props.url_logo ? 'bannerLogo' : '';
    let photo_hide = props.url_photo ? '' : 'd-none';

    return  <div className={banner} style={axiaStyle}>
                <img className={'rounded-circle '+photo_hide} src={props.url_photo} alt="ThePhoto"/>
            </div>;
}




class Infos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ts_start: 0,
            ts_end: 0,
            duree: '00:00:00'
        }
    }

    componentDidMount = () => {
        this.timerID = setInterval(
            () => this.timer(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    timer() {
        let ts_now = Date.now();
        if(!['idle', 'dial', 'end'].includes(this.props.stage)) {
            if(!this.state.ts_start) {
                this.setState({ ts_start: ts_now });
            }
            else {
                if(!this.state.ts_end) {
                    this.setState((state, props) => ({
                          duree: toHHMMSS(Math.ceil(ts_now-state.ts_start)/1000)
                    }));
                } else {
                    clearInterval(this.timerID);
                }
            }
        }
    }

    render() {
        const identity = this.props.identity;
        const stage = this.props.stage;
        const duree = this.state.duree;
        const answered = this.state.answered;

        return  <div className="infoCard px-4 pt-4 pb-2">
                    { identity.name && <h3 className="mt-3 contactName">{identity.name}</h3> }
                    { identity.txt && <p className="introTexte small mb-0">{identity.txt}</p> }
                    <p className={'enCommTexte small mb-0 py-3 '+(stage === "hook"?"":"d-none")}>
                        <span className="text-success h5"><i className='bx bxs-phone-call bx-flashing' ></i>  <span>{_('stat_comm')}</span></span><br/>
                        <span className="text-muted h6">{_('duration')} : {duree}</span>
                    </p>
                    <p className={'enSonnerieTexte small mb-0 py-3 '+(stage === "dial"?"":"d-none")}>
                        <span className="text-primary h5"><i className='bx bxs-phone-call bx-tada' ></i> <span>{_('stat_current')}</span><br/></span>
                    </p>
                    <div className={'enEndTexte small mb-0 py-3 '+(stage === "end"?"":"d-none")}>
                        <p className={'text-muted h6 '+(answered?'d-none':'')}>{_('temps_comm')} {duree}</p>
                        <p className={'text-muted h6 '+(!answered?'d-none':'')}>{_('echec_comm').replace('{e164}', identity.e164)}</p>
                        <p className="text-primary h4">{_('stat_end')}</p>
                    </div>
                </div>;
    }
}

/*function CamControl(props) {
    return  <div className="p-4">
                <select className="form-control" name="cams" id="cams">
                </select>
            </div>;
}*/



// fullscreen à reporter
class Cam extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fullscreen: false
        }
    }

    handleFullScreen = (active=true) => {
        var doc = window.document;
        var docEl = doc.documentElement;

        var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
        var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

        if(active) {
            requestFullScreen.call(docEl);
        }
        else {
            cancelFullScreen.call(doc);
        }
        this.setState ({fullscreen:active})
    }


    handleFullscreenChange = (e) => {
        let fullscreen = false
        if (document.fullscreenElement ||
                document.mozFullScreenElement||
                document.webkitFullscreenElement ||
                document.msFullscreenElement ||
                document.fullscreen ||
                document.mozFullScreen ||
                document.webkitIsFullScreene ||
                document.fullScreenMode ) {
            fullscreen = true
        }
        if(fullscreen) {
            document.querySelector('body').classList.add('viewFullScreen');
        } else {
            document.querySelector('body').classList.remove('viewFullScreen');
        }
        this.setState ({fullscreen})
    }


    componentDidMount = () => {
        document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange, false)
        document.addEventListener('mozfullscreenchange', this.handleFullscreenChange, false)
        document.addEventListener('msfullscreenchange', this.handleFullscreenChange, false)
        document.addEventListener('fullscreenchange', this.handleFullscreenChange, false)
    }


    componentWillUnmount = () => {
         document.removeEventListener('webkitfullscreenchange', this.handleFullscreenChange)
         document.removeEventListener('mozfullscreenchange', this.handleFullscreenChange)
         document.removeEventListener('msfullscreenchange', this.handleFullscreenChange)
         document.removeEventListener('fullscreenchange', this.handleFullscreenChange)
    }


    render() {
        const stage = this.props.stage;
        if(this.state.fullscreen && this.props.stage === "end") {
            document.querySelector('body').classList.remove('viewFullScreen');
            //this.handleFullScreen(false);
        }
        //if(!this.props.show)
        //    return null ;
        if(this.props) ;
        return  <div className={'videoInProgress mx-1 mt-2 mb-2 '+(this.props.show?'':'d-none')} id="videoHtml5">
                    <div className="embed-responsive embed-responsive-16by9 picture">
                        <video id="videoRemote" autoPlay className="embed-responsive-item" src="" muted={['idle', 'dial'].includes(stage)} playsInline></video>
                    </div>
                    <div className="embed-responsive embed-responsive-16by9 pictureInPicture mr-1 mb-1">
                        <video id="videoLocal" autoPlay className="embed-responsive-item shadow" src="" muted={true} playsInline></video>
                    </div>
                    <div className="fullScreenView mr-1 mb-1">
                        { !this.state.fullscreen && <button type="button" className="fullscreenActive btn btn-light" data-toggle="tooltip" title={_('window_open')} onClick={(e) => this.handleFullScreen(true, e) }>
                            <i className='bx bx-fullscreen'></i>
                        </button> }
                        { this.state.fullscreen && <button type="button" className="fullscreenUnActive btn btn-light" data-toggle="tooltip" title={_('window_close')} onClick={(e) => this.handleFullScreen(false, e)}>
                            <i className='bx bx-exit-fullscreen'></i>
                        </button> }
                    </div>
                </div>
    }
}



class Display extends React.Component  {
    /*constructor(props) {
        super(props);
    }*/

    handleChange = (e) => {
        this.props.onDisplayChange(e.target.value);
    }

    render() {
        const callerNum = this.props.callerNum;

        if(!this.props.show)
            return null;


        return  <div className="numberCall px-4 pb-2">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group mb-0">
                                <label htmlFor="output">{_("input_num")}</label>
                                <input
                                    type="text"
                                    id="output"
                                    className="form-control rounded border text-center"
                                    placeholder="Ex : 33 1 70 200 XXX"
                                    value={callerNum}
                                    onChange={this.handleChange} />
                            </div>
                        </div>
                    </div>
                </div>;
    }
}



class Pad extends React.Component {
    constructor(props) {
        super(props);
        this.pad = [
            [ 1 , 2 , 3 ],
            [ 4 , 5 , 6 ],
            [ 7 , 8 , 9 ],
            ["*", 0 ,"#"]
        ];
    }

    handlePress = (touche) => {
        if(!(this.props.stage === 'idle' && ['*', '#'].includes(touche))) {
            this.props.onPress(touche);
        }
    }

    render() {
        const stage = this.props.stage;
        if(!this.props.show)
            return null ;
        return  <div className="dialPad">
                    <div className="row">
                        { this.pad.map((line, x) => {
                            return (<div className="col-12" key={x}>
                                        <div className="row">
                                            {
                                                line.map((touche, y) => {
                                                    return (stage === 'idle'&& ['*', '#'].includes(touche)?
                                                                (<div key={x+"_"+y} className='digit col-4'>&nbsp;</div>)
                                                            :
                                                                (<div key={x+"_"+y} className='digit col-4' data-digit={touche} onClick={(e) => this.handlePress(touche, e)}>
                                                                    {touche}
                                                                </div>));
                                                })
                                            }
                                        </div>
                                    </div>)
                            })
                        }
                    </div>
                </div>;
    }
}


class Button extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isDisabled: false}
    }

    handleAction = (action) => {
        //action.preventDefault(); // GROSDOUTE

        if(this.props.noFlip) {
            this.setState({ isDisabled: true });
            setTimeout(() => this.setState({ isDisabled: false }), 3000);
        } else {
            //this.setState({ isDisabled: true });
        }
        this.props.onAction(action);
    }

    render() {
        const p = this.props ;
        return  <button
                    className={'mx-2 col-auto rounded-circle border-0 '+p.classColor+' '+(this.state.isDisabled?'active':'')+' '+(p.show?'':'d-none')}
                    data-toggle="tooltip"
                    title={p.title}
                    aria-hidden={p.ariaHidden?true:false}
                    onClick={(e) => this.handleAction(p.action, e)}
                    disabled={this.state.isDisabled}>
                    <i className={p.classIcon} ></i>
                </button>;
    }
}



class ActionsBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = { loading: true, auth: {id:'', pwd:''}, e164:'', auto_start:false, caller:'', css:'', logo:'', name:'', pad:false, photo:false, title:'', txt:'', cam:false, error:false, stage:'idle', cam_dispo:false, fullscreen:false, droit_video:false };
        document.body.classList.remove('loading');
    }

    handleAction = (action) => {
        this.props.onAction(action);
    }


    render() {
        return  <div className="actions">
                <div className="text-center callBtn">
                    <div className="row justify-content-center align-items-center">
                        <Button
                            show={['idle'].includes(this.props.stage)}
                            action="audioDial"
                            onAction={this.handleAction}
                            classColor="btn-success btnCallStart"
                            title={_('hook_audio')}
                            noFlip={false}
                            classIcon="bx bxs-phone"/>
                        <Button
                            show={!this.props.cam_dispo && ['dial', 'hook'].includes(this.props.stage)}
                            action="audioEnd"
                            onAction={this.handleAction}
                            classColor="btn-danger btnCallEnd"
                            title={_('hangup')}
                            noFlip={false}
                            classIcon="bx bxs-phone bx-rotate-180"/>
                        <Button
                            show={this.props.cam_dispo && ['idle'].includes(this.props.stage)}
                            action="videoDial"
                            onAction={this.handleAction}
                            classColor="btn-danger btnCallVideoStart"
                            title={_('hook_video')}
                            noFlip={false}
                            classIcon="bx bx-video"/>
                        <Button
                            show={this.props.cam_dispo && ['dial', 'hook'].includes(this.props.stage)}
                            action="videoEnd"
                            onAction={this.handleAction}
                            classColor="btn-danger btnCallVideoEnd"
                            title={_('hangup')}
                            noFlip={false}
                            classIcon="bx bx-phone bx-rotate-180"/>
                        <Button
                            show={['hook'].includes(this.props.stage)}
                            action="mic"
                            onAction={this.handleAction}
                            classColor="border-0 btn-primary btnMic"
                            title={_('micro')}
                            noFlip={true}
                            classIcon={this.props.isHold?'bx bx-microphone-off':'bx bx-microphone'}/>
                        <Button
                            show={this.props.cam_dispo && this.props.cams.length>1 && ['hook'].includes(this.props.stage)}
                            action="switch"
                            onAction={this.handleAction}
                            classColor="btn-primary btnCamSwitch"
                            title={_('cam_switch')}
                            classIcon="fa fa-refresh"
                            noFlip={true}
                            ariaHidden={true}/>
                    </div>
                </div>
            </div>;
    }
}



/*function Credit(props) {
    return  <section className="credits">
                <footer className="footer">
                    <p className="mt-4  text-center">
                        <small>Crafted with <i className='bx bxs-heart bgPink'></i> by <a className="text-uppercase" href="https://www.axialys.com" target="_blank">Axialys</a></small>
                    </p>
                </footer>
            </section>;
}*/



class WrtcCallback extends React.Component {

    //var simpleUser ;

    urlParam = function(name){
        var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
        return results === null?null:results[1] ;
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            auth: {id:'', pwd:''},
            identity: {name:'', e164:'', txt:''},
            auto_start:false,
            caller: '',
            css: '',
            logo:'',
            pad:false,
            photo:false,
            title:'',
            cam:false,
            error:false,
            stage:'idle',
            cam_dispo:false,
            fullscreen:false,
            droit_video:false,
            callerNum:'',
            curCam:0,
            answered: false,
            isHold: false};

        document.body.classList.remove('loading');
    }


    playDTMFTone = async (dtmf) => {
        try {
            switch(dtmf) {
                case '*':
                    dtmf = 'star';
                    break;
                case '#':
                    dtmf = 'hash';
                    break
                default:
            }
            await document.getElementById("dtmfTone_"+dtmf).play() ;
        } catch (e) {
        }
    }

    startRingTone = async () => {
        try {
            await document.getElementById('ringtone').play();
        } catch (e) {
            console.log(e) ;
        }
    }


    stopRingTone = async () => {
        try {
            await document.getElementById('ringtone').pause();
        } catch (e) {
        }
    }

    startAudioRemote = async (who, remoteStream) => {
        document.getElementById('audioRemote').srcObject = remoteStream;
        await document.getElementById('audioRemote').play();
        return;
    };

    camSwitch = async (ref) => {
        //var localStream;
        var streams;

        /*const constraints = {
            audio: true,
            video: {deviceId: ref}
        };*/
        if(window.stream) {
            window.stream.getTracks().forEach(track => {
                track.stop();
            });
        }

        try {
            streams = await navigator.mediaDevices.getUserMedia(navigator.userAgent.includes("iPhone") ? {video: {deviceId: ref}} : {video: {deviceId: ref}, audio: true} );
        } catch(e) {
            streams = await navigator.mediaDevices.getUserMedia(navigator.userAgent.includes("iPhone") ? {video: true} : {video: true, audio: true} );
        }
        //var videoTrack = streams.getVideoTracks()[0];

        //navigator.mediaDevices.getUserMedia({audio: true , video: {deviceId: ref}});
        //var videoTrack = streams.getVideoTracks()[0];
        var sx = this.simpleUser.session;
        var pc = sx.sessionDescriptionHandler.peerConnection;

        //var pc = localVideoTrack() ;
        try {
            /*var sender = pc.getSenders().find(function(s) {  // pc refers to => session.sessionDescriptionHandler.peerConnection;
                return s.track.kind === 'video';
            });

            sender.replaceTrack(videoTrack);*/
            streams.getVideoTracks().forEach(function(track) {
                var sender = pc.getSenders().find(function(s) {
                    return s.track.kind === track.kind;
                });
                sender.replaceTrack(track);
            });
        } catch(e) {
        }
        /*
        var senders = pc.getSenders();
        if(senders.length) {
            senders.forEach(function(sender) {
                    if(sender && sender.track && sender.track.kind === "video") {
                        localStream = new MediaStream();
                        localStream.addTrack(sender.track);
                    }
            });
        }*/
        await gotStream(streams, 'videoLocal');
        return ;
    }

    micOnOff = async (onOff) => {
        var sx = this.simpleUser.session;
        var pc = sx.sessionDescriptionHandler.peerConnection;

        if (pc.getSenders) {
            pc.getSenders().forEach(function (sender) {
                    if (sender.track && sender.track.kind !== "video") {
                        sender.track.enabled = onOff;
                    }
            });
        } else {
            pc.getLocalStreams().forEach(function (stream) {
                    stream.getAudioTracks().forEach(function (track) {
                            if(track && track.kind !== "video") {
                                track.enabled = onOff ;
                            }
                    });
            });
        }
        return;
    }



    async dial(conf) {
        console.log("start") ;
        console.log(conf) ;
        console.log(this.state);

        //await readySwitch();
        if(!conf.callerNum || !conf.callerNum.match(/^33[1-79][0-9]+$/)) {
            conf.callerNum = conf.identity.e164;
        }
        var browserUa = navigator.userAgent.toLowerCase();
        var isSafari = false;
        var isFirefox = false;
        var webrtc_contraints = { mandatory: { echoCancellation: false, googEchoCancellation: false, googAutoGainControl: false, googAutoGainControl2: false, googNoiseSuppression: false, googHighpassFilter: false, googTypingNoiseDetection: false }, optional:[]};
        var sessionDescriptionHandlerFactoryOptions = {iceGatheringTimeout: 500};
        sessionDescriptionHandlerFactoryOptions.constraints = { audio: webrtc_contraints, video: dev.cam?{width:{ideal:640}, height:{ideal:480}}:false } ;

        sessionDescriptionHandlerFactoryOptions.peerConnectionOptions = {
            peerConnectionConfiguration: {
                iceServers: [
                { //urls: "stun:stun.axialys.net:3478" 
                  urls: "stun:stun.l.google.com:19302?transport=udp"
                },
                ],
                stunServers: [],
                turnServers: [],
            }
        } ;

        if (browserUa.indexOf('safari') > -1 && browserUa.indexOf('chrome') < 0) {
            isSafari = true;
            webrtc_contraints = true ;
        }
        else if (browserUa.indexOf('firefox') > -1 && browserUa.indexOf('chrome') < 0) {
            isFirefox = true;
            webrtc_contraints = true ;
        }

        if (isSafari) {
            //sessionDescriptionHandlerFactoryOptions.modifiers = [SIP.Web.Modifiers.stripG722];
        }
        if (isFirefox) {
            sessionDescriptionHandlerFactoryOptions.alwaysAcquireMediaFirst = true;
        }

        await this.startRingTone();
        const server = 'webrtcclients';
        const transportOptions = {
            server: 'wss://'+server+'.axialys.net:8089/ws'
        };
        //const uri = UserAgent.makeURI('sip:'+(conf.callerNum || conf.identity.e164)+'@axialys.net');

        const uri = UserAgent.makeURI('sip:'+(conf.auth.id)+'@axialys.net');
        const userAgentOptions: UserAgentOptions = {
            authorizationUsername: conf.auth.id,
            authorizationPassword: conf.auth.pwd,
            sessionDescriptionHandlerFactoryOptions: sessionDescriptionHandlerFactoryOptions,
            transportOptions,
            uri
        };

        const configuration = {
            sessionDescriptionHandlerFactoryOptions: sessionDescriptionHandlerFactoryOptions,
            delegate: {
                onCallCreated: async () => {
                    console.log("~~~~~~~~~~~~~~");
                    console.log("create") ;
                },
                onCallAnswered: async () => {
                    console.log("~~~~~~~~~~~~~~");
                    console.log("answered") ;
                    this.setState((state, props) => ({
                          stage: state.stage !== 'end'? 'hook': 'end',
                          answered: true
                    }));
                    await this.stopRingTone();

                },
                /*onCallHold: async (isHold) => {
                    this.setState({
                        isHold:isHold
                    });
                },*/
                onCallHangup: async () => {
                    console.log("~~~~~~~~~~~~~~");
                    console.log("hangup") ;
                    this.setState({stage:'end'});
                    await this.stopRingTone();
                    await this.simpleUser.disconnect();
                }
            },
            media: {
                constraints: { audio: true, video: conf.video },
                local: {
                    video: conf.video?document.getElementById('videoLocal'):null,
                },
                remote: {
                    video: conf.video?document.getElementById('videoRemote'):null,
                    audio: conf.video?document.getElementById('audioRemote'):document.getElementById('audioRemote')
                }
            },
            userAgentOptions
        }
        this.simpleUser = new SIP.Web.SimpleUser('wss://'+server+'.axialys.net:8089/ws', configuration);
        window.david = this.simpleUser;
        await this.simpleUser.connect();

        //const target = UserAgent.makeURI('sip:'+(conf.identity.e164)+'@marmont.fr');
        this.simpleUser.call('sip:'+(conf.callerNum)+'@marmont.fr');
    }



    componentDidMount = () => {
        let regexp = /^\/([a-zA-Z0-9]+)\/?$/ ;
        let infos = (window.location.pathname).match(regexp);

        if(infos) {
            let url_init = '/wrtc/'+infos[1]
            fetch(url_init)
                .then(res => res.json())
                .then(
                    async (config) => {
                        if(config.id) {
                            let details = JSON.parse(config.json_details);
                            let etat = {
                                loading: false,
                                auth: {
                                    id: config.id,
                                    pwd: config.pwd
                                },
                                identity: {
                                    name: details.name || '',
                                    e164: config.e164 || '',
                                    txt: details.txt || ''
                                },
                                //autoStart: Boolean(details.auto_start),
                                caller: Boolean(details.caller),
                                logo: details.logo || '',
                                pad: details.pad || '',
                                photo: details.photo || '',
                                droit_video: Boolean(details.video),
                                callerNum: this.urlParam('caller') || ''
                            };
                            document.title = details.title || 'Téléphone';
                            if(details.css) {
                                document.getElementById("axiacss_perso").href = details.css;
                            }
                            if(details.locale) {

                                const liste_trad = ['fr_FR', 'en_GB'];
                                if (liste_trad.includes(details.locale)) {
                                    const url_trad = './locales/' + details.locale + '/wrtc.json';

                                    try {
                                        let _trads;
                                        const mydata = await fetch(url_trad) ;
                                        _trads = await mydata.json();
                                        if(trads.hasOwnProperty('input_num'))
                                            trads = _trads;
                                    } catch(e) {
                                    }
                                }
                            }
                            this.setState(etat);
                            /*try {
                                if(!(rdd.isChrome || rdd.isEdge || rdd.isEdgeChromium || rdd.isFirefox || rdd.isSafari)) {
                                    dev.err = "Ne fonctionne qu'avec: chrome, edge, firefox & safari" ;    
                                }
                                if(rdd.isIOS && !rdd.isSafari) {
                                    dev.err = "Sur IOS merci d'utiliser Safari" ;
                                }
                            } catch(e) {
                            }*/
                            if(!dev.err) {
                                dev = await pre_wrtc();
                            }
                            if(dev.err) {
                                this.setState({loading: false, error:dev.err});
                            } else {
                                etat.cam_dispo = dev.cam && details.video;
                                etat.cams = dev.cams;
                                this.setState(etat);

                                /*if(details.autoStart) {
                                    const video = details.droit_video&&dev.cam
                                    this.dial({
                                        auth: etat.auth,
                                        video: video,
                                        callerNum: etat.callerNum,
                                        identity: etat.identity
                                    }) ;
                                } else {

                                }*/
                                this.beatRef = setInterval(
                                      () => this.beat(),
                                      1000
                                );
                            }


                        } else {
                            this.setState({loading: false, error:"Page non trouvée"});
                        }
                    },
                    (error) => {
                        this.setState({loading: false, error:"Page non trouvée"});
                    }
                );
        } else {

        }
    }

    componentWillUnmount() {
        clearInterval(this.beatRef);
    }

    handleDisplay = (callerNum) => {
        var e164 = callerNum.replace(/[^\d]/g, '').replace(/^(\+)/, '00').replace(/(\+)/, '') ;
        this.setState({callerNum: e164});
    }

    handlePad = (dtmf) => {
        if(this.state.stage === 'hook') {
            /*const options = {
                requestOptions: {
                    body: {
                        contentDisposition: "render",
                        contentType: "application/dtmf-relay",
                        content: "Signal="+dtmf+"\r\nDuration=999"
                    }
                }
            };*/
            this.simpleUser.session.info(''+dtmf);
            this.playDTMFTone(dtmf);
        } else {
            if(Number.isInteger(dtmf) && dtmf>=0 && dtmf<=9) {
                dtmf = ''+dtmf ;
                this.setState((state, props) => ({
                    callerNum: state.callerNum+dtmf
                }));
            }
            this.playDTMFTone(dtmf);
        }
    }

    handleAction = (action) => {
        switch(action) {
            case 'audioDial':
            case 'videoDial':
                const video = this.state.droit_video && this.state.cam_dispo && action === 'videoDial';
                if(!video) {
                    noVideo() ;
                    this.setState({cam_dispo:false, stage:'dial'})
                } else {
                    this.setState({stage:'dial'})
                }
                this.dial({
                    auth: this.state.auth,
                    video: video,
                    callerNum: this.state.callerNum,
                    identity: this.state.identity
                }) ;
                break
            case 'audioEnd':
            case 'videoEnd':
                this.simpleUser.hangup();
                break;
            case 'mic':
                console.log("=>MIC") ;
                if(this.state.isHold) {
                    this.micOnOff(true); // setMute
                }
                else {
                    this.micOnOff(false);
                }
                this.setState({
                        isHold:!this.state.isHold
                });
                break;
            case 'switch':
                // pas la bonne façon de faire
                if(this.state.cams.length) {
                    const cams = this.state.cams;
                    let curCam = ((this.state.curCam+1) % cams.length) ;
                    let ref = cams[curCam];
                    this.setState({curCam: curCam}) ;
                    if(this.state.stage === 'hook') {
                        this.camSwitch(ref);
                    } else {
                        preSwitch(ref) ;
                    }
                    //this.setState({cams:dev.cams, cam_dispo:dev.cam});
                }
                break;
            default:
        }
    }


    beat() {
    }




    render() {
        const isLoaded = !this.state.loading;

        return isLoaded ?
                    this.state.error ?
                        <div id="mask" className="">
                            <p className="msg">{this.state.error}</p>
                        </div>
                    :
                        <section>
                            <div className="container" id="callBackWebRtc">
                                <div className="card text-center">
                                    <Banner
                                        url_photo={this.state.photo}
                                        url_logo={this.state.logo}/>
                                    <Infos
                                        identity={this.state.identity}
                                        answered={this.state.answered}
                                        stage={this.state.stage}/>
                                    <Cam
                                        stage={this.state.stage}
                                        fullscreen={this.state.fullscreen}
                                        show={this.state.cam_dispo && ['dial', 'hook'].includes(this.state.stage)}/>
                                    <Display
                                        callerNum={this.state.callerNum}
                                        onDisplayChange={this.handleDisplay}
                                        show={this.state.caller && ['idle'].includes(this.state.stage)}/>
                                    <Pad
                                        onPress={this.handlePad}
                                        stage={this.state.stage}
                                        show={this.state.pad && ['hook'].includes(this.state.stage)}/>
                                    <ActionsBar
                                        stage={this.state.stage}
                                        cam_dispo={this.state.cam_dispo}
                                        cams={this.state.cams}
                                        isHold={this.state.isHold}
                                        onPressAction={this.handleAction}
                                        onAction={this.handleAction}/>
                                </div>
                            </div>
                        </section>
               :
                    <div></div> ;
    }
}

const domContainer = document.querySelector('#wrtc_callback');
ReactDOM.render(<WrtcCallback/>, domContainer);

